import buildSpaRedirectUrl from '@/modules/@common/functions/build-spa-redirect-url';
import { Base64 } from 'js-base64';

export default (queryString:URLSearchParams): string => {
  if (process.env.GITHUB_OAUTH_URL && process.env.GITHUB_TOKEN) {
    const origin = queryString.get('origin');
    const redirectUrl = buildSpaRedirectUrl(origin || '/my/dashboard');
    return process.env.GITHUB_OAUTH_URL
      .replace('{github_token}', process.env.GITHUB_TOKEN)
      .replace('{redirect_uri}', `${process.env.API_URL}/auth/github-callback/${Base64.encode(redirectUrl)}`);
  }
  return '';
};
