import fetchAuthUser from '@/functions/auth/fetch-auth-user';
import AuthUser from '@/modules/@common/models/auth-user';

export async function redirectIfLoggedIn() {
  const authUser = (await fetchAuthUser() as AuthUser);
  if (authUser.result) {
    const queryString = window.location.search;
    window.location.href = authUser.url ? authUser.url : ('/my/dashboard' + queryString);
  }
}
