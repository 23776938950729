
import Vue, { PropType } from 'vue';
import SvgGithub from '@/assets/svg/SvgGithub.vue';
import getGithubOauthLink from '@/functions/auth/get-github-oauth-link';
import GoogleGsiSignInButton from '@/modules/login/components/GoogleGsiSignInButton.vue';
import { SessionMode } from '@/modules/login/enums/session-mode';

export default Vue.extend({
  name: 'SignInButtonsContainer',
  components: {
    SvgGithub,
    GoogleGsiSignInButton
  },
  props: {
    mode: {
      type: String as PropType<SessionMode>,
      required: true
    }
  },
  data() {
    return {
      urlQuery: ''
    };
  },
  computed: {
    gitHubLink ():string {
      const queryString = new URLSearchParams(this.urlQuery);
      return getGithubOauthLink(queryString);
    },
    SessionMode(): typeof SessionMode {
      return SessionMode;
    }
  },
  mounted() {
    this.urlQuery = window.location.search;
  },
  methods: {
    onError () {
      this.$emit('error');
    }
  }
});
