import { Dictionary } from 'vue-router/types/router';

export default (query: Dictionary<string | (string | null)[]>): string => {
  const errorQuery = query.error;
  if (errorQuery) {
    switch (errorQuery) {
      case 'github':
        return 'GitHub authentication failed.';
      case 'google':
        return 'Google authentication failed.';
      case 'suspended':
        return 'Your account was suspended.';
      default:
        return 'Something went wrong. Try again later.';
    }
  }
  return '';
};
