
import Vue, { PropType } from 'vue';
import axios from 'axios';
import buildSpaRedirectUrl from '@/modules/@common/functions/build-spa-redirect-url';
import resolveApiUrl from '@/modules/@common/functions/resolve-api-url';
import { SessionMode } from '@/modules/login/enums/session-mode';

let google: any = null;
const CLIENT_ID = process.env.GOOGLE_CLIENT_ID;

const SCREEN_SM = 600;
const SCREEN_MD = 960;
const SCREEN_LG = 1264;
const SCREEN_XL = 1800;
const breakpoints = [
  SCREEN_XL,
  SCREEN_LG,
  SCREEN_MD,
  SCREEN_SM,
  0
];

export default Vue.extend({
  name: 'GoogleGsiSignInButton',
  props: {
    context: {
      type: String as PropType<SessionMode>,
      required: true
    }
  },
  data() {
    return {
      windowWidth: 0,
      currentBreakpoint: -1,
      hasResizeListener: false
    };
  },
  computed: {
    buttonText(): string {
      return this.context === SessionMode.SIGN_IN ? 'Sign in with Google' : 'Sign up with Google';
    },
    buttonWidth(): number {
      const windowWidth = this.windowWidth;
      if (windowWidth < SCREEN_SM) {
        return 256;
      } else if (windowWidth < SCREEN_MD) {
        return 230;
      } else if (windowWidth < SCREEN_LG) {
        return 368;
      } else if (windowWidth < SCREEN_XL) {
        return 214;
      } else {
        return 246;
      }
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    // @ts-ignore
    if (window.google) {
      this.initializeGsi();
    } else {
      window.addEventListener('gsi-loaded', this.initializeGsi);
    }
  },
  beforeDestroy() {
    window.removeEventListener('gsi-loaded', this.initializeGsi);
    window.removeEventListener('resize', this.onResize);
    this.hasResizeListener = false;
  },
  methods: {
    shouldCallOnResizeInitializeGsiButton() {
      let newBreakpoint = 0;
      for (const [index, breakpoint] of breakpoints.entries()) {
        if (this.windowWidth >= breakpoint) {
          newBreakpoint = index;
          break;
        }
      }

      if (newBreakpoint !== this.currentBreakpoint) {
        this.currentBreakpoint = newBreakpoint;
        return true;
      }

      return false;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.shouldCallOnResizeInitializeGsiButton()) {
        this.initializeGsiButton();
      }
    },
    initializeGsi () {
      // @ts-ignore
      google = window.google;
      google.accounts.id.initialize({
        client_id: CLIENT_ID,
        cancel_on_tap_outside: false,
        context: this.context,
        callback: this.onSignIn
      });

      this.initializeGsiButton();

      if (!this.hasResizeListener) {
        window.addEventListener('resize', this.onResize);
        this.hasResizeListener = true;
      }

      return null;
    },
    initializeGsiButton() {
      google.accounts.id.renderButton(document.getElementById('signInButton'), {
        text: this.buttonText,
        width: this.buttonWidth
      });
      // display prompt UI
      google.accounts.id.prompt();
    },
    async onSignIn (googleUser: any) {
      const queryError = typeof this.$route.query.error === 'string' ? this.$route.query.error : '';
      try {
        const { data } = await axios({
          method: 'POST',
          url: `${resolveApiUrl()}/auth/google-callback`,
          data: googleUser.credential,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (data.success) {
          this.onResponseSuccess(data.signup);
        } else {
          this.handleGoogleAuthError(queryError);
        }
      } catch {
        this.handleGoogleAuthError(queryError);
      }
    },
    onResponseSuccess (isNewSignup: boolean) {
      const redirectUrl = this.$route.query.origin ? this.$route.query.origin.toString() : '/my/dashboard';
      const appendQuery = new URLSearchParams(window.location.search);
      appendQuery.set(isNewSignup ? SessionMode.SIGN_UP : SessionMode.SIGN_IN, 'google');
      window.location.href = buildSpaRedirectUrl(redirectUrl, appendQuery);
    },
    handleGoogleAuthError (queryError: string) {
      if (queryError !== 'google') {
        window.location.href = '/login?error=google';
      }
    }
  }
});
