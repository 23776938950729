export default (redirectUrl:string, appendQuery?: URLSearchParams): string => {
  const urlQuery = appendQuery || new URLSearchParams();
  const baseUrl = redirectUrl.match(/^https?:\/\//) ? '' : process.env.SPA_URL;
  const normalizedRedirectUrl = redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`;
  const url = new URL(baseUrl + normalizedRedirectUrl);
  const redirectQuery = new URLSearchParams(url.search);
  redirectQuery.forEach((value, key) => {
    urlQuery.set(key, value);
  });
  urlQuery.delete('logout'); // Never append logout
  url.search = urlQuery.toString();
  return url.toString();
};
