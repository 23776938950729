import fetchAuthUser from '@/functions/auth/fetch-auth-user';
import { IApiErrorResult } from '@/models/api-result';

export default async () => {
  try {
    const result = await fetchAuthUser();
    const maintenanceMode = (result as IApiErrorResult).success ? !(result as IApiErrorResult).success : false;
    return maintenanceMode;
  } catch (e: any) {
    return true;
  }
};
