const loadScript = (id: string, url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id) === null) {
      const script = document.createElement('script');
      script.src = url;
      script.id = id;
      script.setAttribute('defer', 'true');
      document.body.appendChild(script);

      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
    } else {
      resolve(); // Script already loaded
    }
  });
};

export default loadScript;
