import loadScript from '@/modules/@common/functions/load-dynamic-script';

const loadCaptcha = async () => {
  try {
    await loadScript('recaptcha', `https://www.google.com/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_SITE_ID}`);
  } catch (error) {
    try {
      // Load hCaptcha script if reCAPTCHA fails
      await loadScript('hcaptcha', 'https://hcaptcha.com/1/api.js');
      const hCaptcha = document.querySelector('.h-captcha') as HTMLElement;
      if (hCaptcha) {
        hCaptcha.style.display = 'block';
      }
    } catch (hCaptchaError) {
      throw new Error(hCaptchaError.message);
    }
  }
};

export default loadCaptcha;
